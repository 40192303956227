import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const InternalLink = styled.div`
  transition: 0.15s ease-in-out opacity;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

const Container = styled.section`
  width: 960px;
  margin: 80px 0;
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { 
    width: auto;
  }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
`;

const BackToTop = ({sizes}) => (
  <Container sizes={sizes}>
    <InternalLink
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
      onClick={() => window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })}
      role="button"
      >
      <div style={{ width: 54, height: 38 }} />
      <div style={{ position: 'relative', top: -28, transform: 'rotate(-45deg)', borderTop: '2px black solid', borderRight: '2px black solid', width: 30, height: 30}} />
      <div style={{ position: 'relative', top: -25, fontFamily: 'cormorant'}}>
        BACK TO TOP
      </div>
    </InternalLink>
  </Container>
);

export default BackToTop;
