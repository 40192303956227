import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import styled from "styled-components"
import Progress from "./progress"

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 308px;
  height: 280px;
  ${"" /* border-right: 1px black solid;
  border-bottom: 1px black solid; */}
`

const Title = styled.div`
  height: 49px;
  color: #060606;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.05em;
  font-weight: 500;
`

const Icon = styled.div`
  position: absolute;
  top: 70px;
  left: 9px;
  transition: 0.1s ease-in-out opacity;
  pointer-events: none;
`

const Play = styled.div`
  position: absolute;
  top: 9px;
  left: 12px;
  width: 0px;
  height: 0px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 13px solid white;
  transition: 0.15s ease-in-out opacity;
`

const Pause = styled.div`
  position: absolute;
  top: 9px;
  left: 11px;
  width: 5px;
  height: 14px;
  border-right: 4px solid #fff;
  border-left: 4px solid #fff;
  transition: 0.15s ease-in-out opacity;
`

const Button = styled.button`
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
  ${"" /* background-color: white; */}
  width: 109px;
  height: 109px;
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
    &:hover ${Icon} {
      opacity: 0.6;
    }
  }
`

const Name = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.05em;
`

const Occupation = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
`

const Preview = ({ node, progress, isPlaying, children, onClick }) => (
  <Container>
    <div
      style={{
        top: 10,
        left: 10,
        borderRight: "1px black solid",
        borderBottom: "1px black solid",
        position: "absolute",
        width: "100%",
        height: "100%",
      }}
    />
    <Title>{node.Title}</Title>
    <Button
      onMouseDown={e => {
        onClick()
        e.preventDefault()
      }}
      onKeyPress={onClick}
    >
      <Img
        style={{ width: "109px", height: "109px", pointerEvents: "none" }}
        fixed={node.image.childImageSharp.fixed}
        alt=""
      />
      <Icon>
        <div style={{ height: "28px", width: "28px" }}>
          <Progress radius={18} progress={progress} />
        </div>
        <Play style={{ opacity: isPlaying ? 0 : 1 }} />
        <Pause style={{ opacity: isPlaying ? 1 : 0 }} />
      </Icon>
      {children}
    </Button>
    <Name>{node.name}</Name>
    <Occupation>{node.Occupation}</Occupation>
  </Container>
)

export default Preview
