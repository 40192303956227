import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

const Progress = ({radius, stroke, progress}) => {
  const normalizedRadius = useRef(radius - stroke * 2);
  const circumference = useRef(normalizedRadius.current * 2 * Math.PI);
  const strokeDashoffset = circumference.current - progress * circumference.current;
  // transform="rotate(-90), translate(2, -2)"
  return (
    <div style={{ transform: 'rotate(-90deg) translate(0px,5px)' }}>
      <svg
        height={radius * 2}
        width={radius * 2}
      >
        <circle
          stroke="white"
          fill="transparent"
          strokeWidth={stroke}
          style={{ strokeDashoffset }}
          r={normalizedRadius.current}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke="#FF4545"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference.current + ' ' + circumference.current}
          style={{ strokeDashoffset }}
          r={normalizedRadius.current}
          cx={radius}
          cy={radius}
        />
      </svg>
    </div>

  );
};

Progress.defaultProps = {
  radius: 15,
  stroke: 2,
  progress: 0.35,
};

export default Progress;
