import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import comingsoon0 from '../images/comingsoon0.png';
import comingsoon1 from '../images/comingsoon1.png';
import comingsoon2 from '../images/comingsoon2.png';
import comingsoon3 from '../images/comingsoon3.png';
import ExternalLink from './externalLink';

const Container = styled.section`
  display: flex;
  align-items: center;
  margin: 70px 30px;
  flex-direction: column;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { width: 470px; }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { width: 380px; }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { width: 320px; }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { width: 280px; }
`;

const Header = styled.div`
  font-weight: 600; 
  font-size: 36px;
  text-align: center;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { font-size: 30px; }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { font-size: 24px; }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { font-size: 20px; }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
`;

const TextMain = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-weight: 600;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: space-between;
  mix-blend-mode: normal;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin-top: 40px;
`;

const Button = styled.div`
  font-family: 'Noto Serif JP';
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  border: 1px solid black;
  border-radius: 8px; 
  transition: 0.15s ease-in-out opacity;
  width: 500px;
  height: 89px;
  font-weight: 600;
  font-size: 20px;

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { width: 360px;  font-size: 18px; }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { width: 320px;  font-size: 16px; }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { width: 240px;  font-size: 14px; }
`;

const ComingSoon = ({sizes}) => (
  <Container sizes={sizes}>
    <Header sizes={sizes}>
      VOOX × 日経ビジネス人文庫20周年コラボ企画
    </Header>
    <div style={{ marginTop: '20px', marginBottom: '38px', borderBottom: '2px solid #FF0000', width: '300px'}} />
    <TextMain>
      働く人たちのための文庫シリーズとして2000年11月に創刊.
      <br />
      ビジネス・経済から、教養、歴史、自己啓発まで、幅広いテーマのラインナップを揃えています。
      <br />
      日経ビジネス人文庫は2020年10月に20周年をむかえました。
      <br />
      これを記念して、VOOXと共に厳選したビジネス書のエッセンスを、
      <br />
      著者本人による音声解説で10分でお届けします。
    </TextMain>
    <ImageContainer>
      <img style={{ width: 91, height: 133, margin: 12}} src={comingsoon0} />
      <img style={{ width: 91, height: 133, margin: 12 }} src={comingsoon1} />
      <img style={{ width: 91, height: 133, margin: 12 }} src={comingsoon2} />
      <img style={{ width: 91, height: 133, marginLeft: 12, marginTop: 12 }} src={comingsoon3} />
    </ImageContainer>

    <ExternalLink
      href='https://www.nikkeibp.co.jp/books/promotion/nbb20/'
      style={{
        margin: 10,
        textDecoration: 'none',
        color: 'black',
      }}
    >
      <Button sizes={sizes}>
        <div style={{margin: 20}}>日経ビジネス人文庫20周年特設サイトへ  &emsp;&emsp;＞</div>
      </Button>
    </ExternalLink>
  </Container>
);

export default ComingSoon;
