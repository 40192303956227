import React from "react"

function Forward() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="19"
      fill="none"
      viewBox="0 0 11 19"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M10.03 9.5l-8.484 9.014-1.093-1.028L7.97 9.5.453 1.514 1.546.486 10.029 9.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default Forward
