import React from "react"
import styled from "styled-components"

import ExternalLink from "./externalLink"

const Container = styled.section`
  display: flex;
  align-items: center;
  margin: 70px 30px;
  flex-direction: column;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.tablet}px) {
    width: 470px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) {
    width: 380px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobile}px) {
    width: 320px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) {
    width: 280px;
  }
`

const RowPriceContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 30px 100px 30px;
  flex-direction: row;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.tablet}px) {
    flex-direction: column;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) {
    flex-direction: column;
  }
  @media (max-width: ${({ sizes }) => sizes.mobile}px) {
    flex-direction: column;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) {
    flex-direction: column;
  }
`
const RowContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: row;
`
const PriceContent = styled.div`
  display: flex;
  width: 450px;
  height: 520px;
  align-items: center;
  margin: 16px 12px;
  padding: 3px 10px;
  flex-direction: column;
  border-right: thin solid #000000;
  border-bottom: thin solid #000000;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.tablet}px) {
    width: 400px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) {
    width: 380px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobile}px) {
    width: 320px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) {
    width: 260px;
  }
`
const FreeTitleComponent = styled.div`
  display: flex;
  width: 420px;
  height: 160px;
  align-items: center;
  margin: 0px 0px 60px 0px;
  flex-direction: column;
  background-color: #c4c4c4;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.tablet}px) {
    width: 380px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) {
    width: 340px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobile}px) {
    width: 280px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) {
    width: 240px;
  }
`
const PremiumTitleComponent = styled.div`
  display: flex;
  width: 420px;
  height: 160px;
  align-items: center;
  margin: 0px 0px 60px 20px;
  flex-direction: column;
  background-color: #000000;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.tablet}px) {
    width: 380px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) {
    width: 340px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobile}px) {
    width: 280px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) {
    width: 240px;
  }
`
const MainText = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 16px;
  letter-spacing: 0.05em;
`
const FreeTitleText = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.05em;
  margin: auto auto 15px auto;
`
const FreeTitleBodyText = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  margin: 0 auto auto auto;
  letter-spacing: 0.05em;
`
const PremiumTitleText = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.05em;
  margin: auto auto 15px auto;
  color: #ffffff;
`
const PremiumTitleBodyText = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  margin: 0 auto auto auto;
  letter-spacing: 0.05em;
  color: #ffffff;
`
const FreePriceText = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 44px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.1em;
`
const PremiumPriceText = styled.div`
  font-weight: 700;
  font-size: 48px;
  line-height: 40px;
  text-align: center;
  margin: 0px 0px 16px 8px;
  letter-spacing: 0.05em;
`
const PriceSubText = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.05em;
`
const PriceBodyText = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  letter-spacing: 0.05em;
  margin: auto auto 0 auto;
`
const RedText = styled.div`
  font-weight: 700;
  font-size: 44px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ff0606;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.tablet}px) {
    font-size: 32px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) {
    font-size: 24px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobile}px) {
    font-size: 20px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) {
  }
`
const FreeText = styled.div`
  font-weight: 700;
  font-size: 44px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.05em;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.tablet}px) {
    font-size: 32px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) {
    font-size: 24px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobile}px) {
    font-size: 20px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) {
  }
`
const MonthText = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  padding-bottom: 4px;
  letter-spacing: 0.05em;
`
const TaxText = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 40px;
  text-align: left;
  padding-bottom: 4px;
  letter-spacing: 0.05em;
`
const OutlineText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #8b8b8b;
  margin: 8px 0px 0px 0px;
`
const ComingSoonButton = styled.div`
  font-family: "Cormorant";
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 75px;
  // border: 0px solid black;
  border-radius: 8px;
  transition: 0.15s ease-in-out opacity;
  width: 300px;
  height: 88px;
  font-weight: 400;
  font-size: 22px;
  opacity: 0.5;

  /* &:hover {
    opacity: 0.5;
  } */

  @media (max-width: ${({ sizes }) => sizes.desktop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.tablet}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) {
    width: 360px;
    font-size: 18px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobile}px) {
    width: 320px;
    font-size: 16px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) {
    width: 240px;
    font-size: 14px;
  }
`
const Button = styled.div`
  font-family: "Noto Serif JP";
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0px;
  border: 1px solid black;
  border-radius: 8px;
  transition: 0.15s ease-in-out opacity;
  width: 300px;
  height: 88px;
  font-weight: 600;
  font-size: 20px;

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: ${({ sizes }) => sizes.desktop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.tablet}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) {
    width: 360px;
    font-size: 18px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobile}px) {
    width: 320px;
    font-size: 16px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) {
    width: 240px;
    font-size: 14px;
  }
`

const PremiumPlan = ({ sizes, appUrl }) => (
  <Container sizes={sizes}>
    <RowPriceContainer sizes={sizes}>
      <PriceContent sizes={sizes}>
        <FreeTitleComponent sizes={sizes}>
          <FreeTitleText>FREE</FreeTitleText>
          <FreeTitleBodyText>
            まずはVOOXのコンテンツを
            <br />
            聴いてみたい！という方におすすめ
          </FreeTitleBodyText>
        </FreeTitleComponent>
        <FreePriceText>無料</FreePriceText>
        <PriceBodyText>✔️毎月10エピソード</PriceBodyText>
        <OutlineText>※エピソードカウントは毎月リセットされます</OutlineText>
        <ComingSoonButton sizes={sizes}>
          <div style={{ margin: 10 }}></div>
        </ComingSoonButton>
      </PriceContent>
      <PriceContent sizes={sizes}>
        <PremiumTitleComponent sizes={sizes}>
          <PremiumTitleText>PREMIUM</PremiumTitleText>
          <PremiumTitleBodyText>
            とことん色んなスピーカーのお話を
            <br />
            聴きたい！という方におすすめ
          </PremiumTitleBodyText>
        </PremiumTitleComponent>
        <RowContainer>
          <MonthText>月々</MonthText>
          <PremiumPriceText>¥1,000</PremiumPriceText>
          <TaxText>（税込）</TaxText>
        </RowContainer>
        <PriceBodyText>
          ✔️コンテンツ聴き放題
          <br />
          ✔️自動再生
        </PriceBodyText>
        <ComingSoonButton sizes={sizes}>
          <div style={{ margin: 10 }}></div>
        </ComingSoonButton>
      </PriceContent>
    </RowPriceContainer>
  </Container>
)

export default PremiumPlan
