import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import LogoVoox from "../svg/logoVooxWhite"
import LogoIg from "../svg/logoIg"
import LogoFb from "../svg/logoFb"
import LogoTw from "../svg/logoTw"

import AfNavBg from "../images/af-nav-bg.jpg"

const Spacer = styled.div`
  flex-grow: 1;
`

const VooxWrapper = styled.div`
  display: none;

  @media (max-width: 840px) {
    display: initial;
  }
`

const Header = styled.div`
  /* background-image: url(${AfNavBg}); */

  background-repeat: repeat;
  background: linear-gradient(
    90deg,
    #ffed46,
    #aeff9a,
    #6af6ff,
    #5a6bff,
    #f257ff,
    #ff4949,
    #f3a26b,
    #f3a26b
  );
`

const LogoContainer = styled.div`
  position: absolute;
  display: flex;
  flex-grow: 1;
  left: 0;
  right: 0;
  justify-content: center;
  margin: 0 25px;
`

const LogoFixedWidth = styled.div`
  width: 100%;
  max-width: 980px;
`

const DesktopNews = styled.div`
  @media (max-width: 840px) {
    display: none;
  }
`

const MobileNews = styled.div`
  display: none;
  position: "relative";

  @media (max-width: 840px) {
    display: flex;
    flex-direction: column;
    padding: 8px 20px;
  }
`

const LinkBar = () => (
  <div>
    <Header
      style={{
        position: "relative",
        padding: "0 22px",
        height: "50px",
        backgroundColor: "black",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <LogoContainer>
        <LogoFixedWidth>
          <VooxWrapper>
            <LogoVoox />
          </VooxWrapper>
          <DesktopNews>
            お知らせ:{" "}
            <Link to="/dogs/" replace style={{ color: "black" }}>
              4月1日、VOOX for DOGSをリリースしました。CLICK HERE!
            </Link>
          </DesktopNews>
        </LogoFixedWidth>
      </LogoContainer>
      {/* <LogoVoox /> */}
      {/* <Spacer /> */}
      <LogoIg />
      <LogoTw />
      <LogoFb />
    </Header>
    <MobileNews>
      お知らせ:{" "}
      <Link to="/dogs/" replace style={{ color: "black" }}>
        4月1日、VOOX for DOGSをリリースしました。CLICK HERE!
      </Link>
    </MobileNews>
  </div>
)

export default LinkBar
