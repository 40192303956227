import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import LogoVoox from '../svg/logoVoox';
import Clock from '../svg/clock';
import ButtonsBox from './buttonsBox';

import ImgBg from '../images/iphone-bg.png';
import ImgMask from '../images/iphone-mask.png';
import ImgNormal from '../images/iphone.png';

const Container = styled.section`
  margin: 51.85px auto;
  max-width: 980px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #000000;
`;

const Col1 = styled.div`
  width: 620px;
  margin: 38px 38px 38px 0;
  ${'' /* display: flex; */}
  ${'' /* flex-direction: column; */}
  ${'' /* justify-content: center; */}
  ${'' /* align-items: center; */}

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { width: 405px; margin: 0; }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { width: 355px; }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { width: 300px; }
`;

const Col2 = styled.div`
  margin: 0 0 0 38px;
  min-width: 262px;
  min-height: 606px;
  position: relative;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { display: none; min-width: 0; margin: 0; }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
`;

const SubheaderBox = styled.div`
  ${'' /* background-color: lightblue; */}
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin: 40px 0;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { margin: 25px 0 0 0;}
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
`;

const Subheader = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  margin: 5px 10px;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { font-size: 28px; margin: 5px 25px;}
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
`;

const ClockBox = styled.div`
  ${'' /* background-color: pink; */}
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-around; 
  align-items: center;
  flex-wrap: wrap;
`;

const ClockDescBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
`;

const Col1Image = styled.div`
  display: none;
  position: relative;
  width: 262px;
  height: 606px;
  margin: auto;
  
  @media (max-width: ${({ sizes }) => sizes.desktop}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { display: flex; justify-content: center; align-items: center; }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
`;

// const ButtonsBox = styled.div`
//   margin-top: 25px;
//   margin-bottom: 15px;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   align-items: center;
// `;

const ClockDesc = styled.div`
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.05em;
  font-weight: 500;
  padding: 10px; 
`;

const ImaKara = styled.div`
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.05em;
  color: #FF0000;
`;

const NormalImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(${ImgNormal});
  width: 262px;
  height: 606px;
  background-size: cover;
  background-position: center;
`;

const MaskedImg = styled.div`
  position: absolute;
  top: 50px;
  left: 0px;
  background-image: url(${ImgBg});
  mask-image: url(${ImgMask});
  background-size: cover;
  background-position: 0% 0%;
  background-position: 0% ${({ bgPos }) => bgPos}%; 
  background-repeat: no-repeat;
  width: 269px;
  height: 504px;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;

  transition: 1.15s ease-in-out background-position;
`;

const Header = ({ sizes }) => {
  const [bgPos, setBgPos] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setBgPos((bgPos) => (bgPos + 25) % 125);
    }, 3500);
    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <Col1 sizes={sizes}>
        <LogoVoox w="92%" h="92%" />
        <SubheaderBox sizes={sizes}>
          <Subheader sizes={sizes}>
            今日から始めるスマートな10分習慣
          </Subheader>
        </SubheaderBox>
        <Col1Image sizes={sizes}>
          <NormalImg />
          <MaskedImg bgPos={bgPos} />
        </Col1Image>

        <div style={{ marginTop: '10px', borderBottom: '1px solid #000000' }} />

        <ClockBox>
          <Clock />
          <ClockDescBox>
            <ClockDesc>
              プロフェッショナルから学べる音声メディア
            </ClockDesc>

            <ButtonsBox />
          </ClockDescBox>
        </ClockBox>
      </Col1>

    </Container>
  );
};




export default Header;
