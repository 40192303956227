import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from 'styled-components';

const Container = styled.div`
  border-left: ${({ idx }) => (idx !== 0 ? '1px black dashed' : 'none')};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { 
    border-left: ${({ idx }) => (idx === 1 ? '1px black dashed' : 'none')};
    margin-top: ${({ idx }) => (idx === 2 ? '15' : '0')}px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) {     border-left: none;
    margin-top: 15px;}
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
`;

const Feature = ({node, idx, sizes}) => (
  <Container sizes={sizes} idx={idx}>
    {node.copy.split('\n').map((line) => (
      <div
        key={line}
        style={{
          width: 318,
          textAlign: 'center',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '28px',
        }}
      >
        {line}
      </div>
    ))}
    <div style={{ marginTop: '16px'}}>
      <Img fixed={node.image.childImageSharp.fixed} alt="" />
    </div>
  </Container>
);

export default Feature;
