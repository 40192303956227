import React from 'react';

const Clock = (props) => (
  <div>
    <svg width="172" height="172" viewBox="0 0 172 172" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M86 172C38.5723 172 0 133.428 0 86C0 38.5723 38.5723 0 86 0C133.428 0 172 38.5723 172 86C172 133.428 133.428 172 86 172ZM86 1.70297C39.5089 1.70297 1.70297 39.5089 1.70297 86C1.70297 132.491 39.5089 170.297 86 170.297C132.491 170.297 170.297 132.491 170.297 86C170.297 39.5089 132.491 1.70297 86 1.70297Z" fill="black" />
      <path d="M132.661 53.2179C122.018 38.0614 104.562 28.9505 86 28.9505V15.3268C108.99 15.3268 130.618 26.5664 143.816 45.3842L132.661 53.2179Z" fill="#FF0000" />
      <path d="M86.8515 0.85144H85.1485V8.51481H86.8515V0.85144Z" fill="black" />
      <path d="M86.8515 0.85144H85.1485V8.51481H86.8515V0.85144Z" fill="black" />
      <path d="M72.046 2.04208L70.3689 2.33789L71.7 9.88477L73.3771 9.58897L72.046 2.04208Z" fill="black" />
      <path d="M57.6598 5.73777L56.0595 6.32019L58.6804 13.5215L60.2807 12.939L57.6598 5.73777Z" fill="black" />
      <path d="M44.1276 11.8592L42.6528 12.7107L46.4845 19.3472L47.9593 18.4957L44.1276 11.8592Z" fill="black" />
      <path d="M31.8873 20.2053L30.5827 21.2998L35.5079 27.1707L36.8126 26.0762L31.8873 20.2053Z" fill="black" />
      <path d="M21.2866 30.6352L20.1921 31.9398L26.063 36.8651L27.1575 35.5604L21.2866 30.6352Z" fill="black" />
      <path d="M12.7231 42.7052L11.8716 44.1799L18.5081 48.0116L19.3596 46.5368L12.7231 42.7052Z" fill="black" />
      <path d="M6.25985 56.0347L5.67743 57.635L12.8787 60.2559L13.4611 58.6556L6.25985 56.0347Z" fill="black" />
      <path d="M2.28683 70.3306L1.99103 72.0077L9.53791 73.3388L9.83372 71.6617L2.28683 70.3306Z" fill="black" />
      <path d="M8.51487 85.1486H0.851501V86.8515H8.51487V85.1486Z" fill="black" />
      <path d="M9.58808 98.626L2.0412 99.9586L2.33735 101.636L9.88423 100.303L9.58808 98.626Z" fill="black" />
      <path d="M12.9396 111.716L5.73834 114.336L6.32059 115.936L13.5219 113.316L12.9396 111.716Z" fill="black" />
      <path d="M18.4512 123.97L11.814 127.801L12.6653 129.276L19.3025 125.445L18.4512 123.97Z" fill="black" />
      <path d="M26.0796 135.189L20.2095 140.116L21.3043 141.42L27.1744 136.494L26.0796 135.189Z" fill="black" />
      <path d="M35.5669 144.846L30.6401 150.716L31.9446 151.811L36.8713 145.94L35.5669 144.846Z" fill="black" />
      <path d="M46.5403 152.635L42.7094 159.272L44.1843 160.123L48.0152 153.486L46.5403 152.635Z" fill="black" />
      <path d="M58.6485 158.541L56.0284 165.742L57.6287 166.324L60.2488 159.123L58.6485 158.541Z" fill="black" />
      <path d="M71.67 162.164L70.3373 169.711L72.0144 170.007L73.3471 162.46L71.67 162.164Z" fill="black" />
      <path d="M86.8515 163.485H85.1485V171.149H86.8515V163.485Z" fill="black" />
      <path d="M100.298 162.119L98.6207 162.415L99.9518 169.962L101.629 169.666L100.298 162.119Z" fill="black" />
      <path d="M113.321 158.475L111.721 159.058L114.342 166.259L115.942 165.677L113.321 158.475Z" fill="black" />
      <path d="M125.436 152.695L123.962 153.546L127.793 160.183L129.268 159.331L125.436 152.695Z" fill="black" />
      <path d="M136.488 144.833L135.184 145.927L140.109 151.798L141.414 150.704L136.488 144.833Z" fill="black" />
      <path d="M145.933 135.131L144.839 136.436L150.71 141.361L151.804 140.056L145.933 135.131Z" fill="black" />
      <path d="M153.56 124.032L152.709 125.506L159.345 129.338L160.197 127.863L153.56 124.032Z" fill="black" />
      <path d="M159.123 111.747L158.54 113.347L165.742 115.968L166.324 114.368L159.123 111.747Z" fill="black"/>
      <path d="M162.46 98.6574L162.164 100.334L169.711 101.666L170.007 99.9885L162.46 98.6574Z" fill="black" />
      <path d="M171.149 85.1486H163.485V86.8515H171.149V85.1486Z" fill="black" />
      <path d="M169.669 70.37L162.122 71.7026L162.419 73.3797L169.966 72.0471L169.669 70.37Z" fill="black" />
      <path d="M165.673 56.0579L158.472 58.678L159.054 60.2783L166.255 57.6582L165.673 56.0579Z" fill="black" />
      <path d="M159.294 42.6585L152.656 46.4894L153.508 47.9643L160.145 44.1334L159.294 42.6585Z" fill="black" />
      <path d="M150.705 30.5791L144.835 35.5059L145.93 36.8103L151.8 31.8836L150.705 30.5791Z" fill="black" />
      <path d="M140.065 20.1899L135.138 26.0601L136.442 27.1549L141.369 21.2848L140.065 20.1899Z" fill="black" />
      <path d="M127.86 11.7948L124.029 18.432L125.504 19.2833L129.335 12.6461L127.86 11.7948Z" fill="black" />
      <path d="M114.365 5.68179L111.745 12.8831L113.345 13.4653L115.965 6.26404L114.365 5.68179Z" fill="black" />
      <path d="M99.9923 1.98717L98.6596 9.53406L100.337 9.8302L101.669 2.28332L99.9923 1.98717Z" fill="black" />
      <path d="M60.9663 67.2672C60.7109 66.5009 60.1149 65.9049 59.1782 65.6494C58.2416 65.394 56.7941 65.2237 54.7505 65.2237C54.6654 65.2237 54.5802 65.0534 54.5802 64.7128C54.5802 64.3722 54.6654 64.2019 54.7505 64.2019C56.6238 64.2019 58.1564 64.2019 59.1782 64.2871L65.4792 64.3722L71.695 64.2871C72.802 64.2019 74.2495 64.2019 76.2079 64.2019C76.3782 64.2019 76.4634 64.3722 76.4634 64.7128C76.4634 65.0534 76.3782 65.2237 76.2079 65.2237C74.3347 65.2237 72.8871 65.394 71.9505 65.7346C71.0139 66.0752 70.4178 66.5861 70.0772 67.4375C69.7366 68.2039 69.5663 69.4811 69.5663 71.2692V90.5979C69.5663 92.3861 69.7366 93.6633 69.9921 94.5148C70.2475 95.2811 70.8436 95.8771 71.7802 96.1326C72.7168 96.388 74.1644 96.5583 76.2079 96.5583C76.3782 96.5583 76.4634 96.7286 76.4634 97.0692C76.4634 97.4098 76.3782 97.5801 76.2079 97.5801C74.3347 97.5801 72.802 97.5801 71.7802 97.495L65.4792 97.4098L59.2634 97.495C58.1564 97.5801 56.7089 97.5801 54.7505 97.5801C54.6654 97.5801 54.5802 97.4098 54.5802 97.0692C54.5802 96.7286 54.6654 96.5583 54.7505 96.5583C56.6238 96.5583 58.0713 96.388 59.0079 96.0474C59.9446 95.7069 60.5406 95.196 60.8812 94.3445C61.2218 93.5781 61.3921 92.3009 61.3921 90.5128V71.1841C61.3921 69.396 61.2218 68.1187 60.9663 67.2672Z" fill="black" />
      <path d="M90.9386 96.3029C88.1287 94.7703 86 92.6416 84.3822 89.9168C82.7644 87.1921 81.998 84.1267 81.998 80.7208C81.998 77.3148 82.7644 74.3346 84.3822 71.6099C86 68.9703 88.1287 66.8416 90.9386 65.394C93.7485 63.8614 96.899 63.1802 100.475 63.1802C104.052 63.1802 107.202 63.9465 109.927 65.4792C112.652 67.0119 114.865 69.1406 116.313 71.7802C117.846 74.5049 118.612 77.5703 118.612 80.8911C118.612 84.297 117.846 87.3623 116.313 90.002C114.78 92.7267 112.566 94.8554 109.842 96.3029C107.117 97.8356 103.966 98.602 100.475 98.602C96.899 98.602 93.6634 97.8356 90.9386 96.3029ZM107.287 92.897C108.65 90.3425 109.331 86.6812 109.331 81.998C109.331 76.2079 108.394 71.8653 106.606 69.0554C104.818 66.2455 102.519 64.798 99.7089 64.798C96.9842 64.798 94.9406 66.1604 93.4079 68.8C91.8753 71.4396 91.1941 75.101 91.1941 79.6138C91.1941 84.7227 92.1307 88.895 93.9188 91.9604C95.7921 95.1109 98.1763 96.6435 101.242 96.6435C103.966 96.8138 105.925 95.5366 107.287 92.897Z" fill="black" />
      <path d="M81.7425 114.354C81.7425 113.418 81.3168 112.737 80.2099 112.737C79.2732 112.737 78.5069 113.333 78.0812 113.929V120.826H75.3564V114.354C75.3564 113.418 74.9307 112.737 73.8237 112.737C72.8871 112.737 72.1208 113.333 71.695 113.929V120.826H68.9703V110.608H71.695V111.97C72.1208 111.374 73.4831 110.352 75.0158 110.352C76.5485 110.352 77.4851 111.034 77.8257 112.226C78.4218 111.289 79.7841 110.352 81.3168 110.352C83.1901 110.352 84.297 111.374 84.297 113.418V120.826H81.7425V114.354Z" fill="black" />
      <path d="M86.8514 107.457C86.8514 106.606 87.6178 105.84 88.4693 105.84C89.3208 105.84 90.0871 106.521 90.0871 107.457C90.0871 108.309 89.4059 109.075 88.4693 109.075C87.6178 109.075 86.8514 108.309 86.8514 107.457ZM87.1069 110.523H89.8316V120.741H87.1069V110.523Z" fill="black" />
      <path d="M99.3683 114.61C99.3683 113.162 98.6019 112.737 97.495 112.737C96.4732 112.737 95.6218 113.333 95.196 113.929V120.826H92.4713V110.608H95.196V111.97C95.8772 111.204 97.0693 110.352 98.7722 110.352C100.986 110.352 102.093 111.63 102.093 113.588V120.826H99.3683V114.61Z" fill="black" />
    </svg>
  </div>
);

export default Clock;
