import React, { useEffect, useState, useRef, useCallback } from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LinkBar from "../components/linkBar"
import LinkBarAf from "../components/linkBarAf"
import WipSplash from "../components/wipSplash"
import Preview from "../components/preview"
import Feature from "../components/feature"
import Modal from "../components/modal"
import Footer from "../components/footer"
import Header from "../components/header"
import PremiumPlan from "../components/premiumPlan"
import ComingSoon from "../components/comingSoon"
import BackToTop from "../components/backToTop"
import Forward from "../svg/forward"

import { useSiteMetadataSizes } from "../hooks/useSiteMetadata"

import Bars from "../svg/bars"
import TryVoox from "../components/tryVoox"

const previewMode = false

const Features = styled.section`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 90px 0;
  margin-bottom: 50px;
  max-width: 980px;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptop}px) {
    max-width: 800px;
    flex-wrap: wrap;
  }
  @media (max-width: ${({ sizes }) => sizes.tablet}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) {
    max-width: 507px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobile}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) {
  }
`

const Previews = styled.section`
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 28px;
  row-gap: 30px;
  width: 980px;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 90px 0;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) {
    width: auto;
  }
  @media (max-width: ${({ sizes }) => sizes.laptop}px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${({ sizes }) => sizes.tablet}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: ${({ sizes }) => sizes.mobile}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) {
  }
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SubheaderContainer = styled.section`
  width: 100%;
  height: 50px;
`

const Subheader = styled.div`
  font-weight: 700;
  font-size: 36px;
  ${"" /* line-height: 36px; */}
  letter-spacing: 0.05em;
  text-align: center;
`

const SubheaderLine = styled.div`
  margin: auto;
  width: ${({ w }) => w}px;
  height: 20px;
  border-bottom: 2px solid #ff0606;
`

const ToSpeakerContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 140px;

  a {
    color: #000000;
    text-decoration: none !important;
  }
`

const ToSpeakerList = styled.div`
  display: flex;
  align-items: center;
`

const ToSpeakerText = styled.span`
  margin-right: 4px;
  padding-bottom: 2px;
  font-size: 20px;
  font-weight: medium;
  border-bottom: 1px solid #000000;
  text-decoration: none;
`

const Line2 = styled.div`
  margin-top: 64px;
  margin-bottom: 38px;
  border-bottom: 1px solid #000000;
  width: 981px;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptop}px) {
    width: 700px;
  }
  @media (max-width: ${({ sizes }) => sizes.tablet}px) {
    width: 520px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) {
    width: 460px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobile}px) {
    width: 240px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) {
  }
`

export default ({ data, pageContext }) => {
  const appUrl = pageContext.appUrl
  const [audioIdx, setAudioIdx] = useState(-1)
  const [audioProgress, setAudioProgress] = useState(0)
  const audioRef = useRef(null)
  const sizes = useSiteMetadataSizes()

  const onPreviewClick = idx => {
    if (idx === audioIdx) {
      if (audioRef.current.paused) {
        audioRef.current.play()
      } else {
        audioRef.current.pause()
      }
    } else {
      if (audioRef.current) {
        audioRef.current.pause()
        audioRef.current.currentTime = 0
        setAudioProgress(0)
      }
      setAudioIdx(idx)
    }
  }

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("timeupdate", () => {
        setAudioProgress(
          audioRef.current.currentTime / audioRef.current.duration
        )
      })
      audioRef.current.play()
    }
  }, [audioIdx])

  const isPlaying = () => {
    if (audioRef.current) {
      return !audioRef.current.paused
    }
    return false
  }

  return (
    <Layout>
      <SEO title="VOOX" />
      {previewMode ? (
        <WipSplash />
      ) : (
        <>
          <LinkBar />
          <Main>
            <Header sizes={sizes} />
            <ToSpeakerContainer>
              <Link to="/episodes/" replace>
                <ToSpeakerList>
                  <ToSpeakerText>スピーカーを見る</ToSpeakerText>
                  <Forward />
                </ToSpeakerList>
              </Link>
            </ToSpeakerContainer>
          </Main>
          <Footer sizes={sizes} />
        </>
      )}
    </Layout>
  )
}
